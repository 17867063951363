<template>
    <div>
      <div class="card">
        <h1 class="text-center text-primary">设置产品数据</h1>
        
        <h5>ASIN:</h5>
        <InputText class="p-inputtext-lg" :class="{'p-invalid':asinerror}" style="width:100%" type="text" v-model="asin" placeholder="请输入ASIN" />
        <h5>图片网址:</h5>
        <InputText class="p-inputtext-lg" :class="{'p-invalid':imageurlerror}" style="width:100%;"  type="text" v-model="imageurl" placeholder="请输入图片网址" />
        <h5>短标题:</h5>
        <InputText class="p-inputtext-lg" :class="{'p-invalid':imageurlerror}" style="width:100%;"  type="text" v-model="subTitle" placeholder="请输入短标题" />
        <h5>中文名称:</h5>
        <InputText class="p-inputtext-lg mb-4" :class="{'p-invalid':imageurlerror}" style="width:100%;"  type="text" v-model="productNameZh" placeholder="请输入中文名称" />

        <Button @click="submitimageurl" label="提交" class="p-button-lg" />
        <Button @click="cleardata" label="清除" class="p-button-lg ml-4" />
        <h4 :style="{'color':tipscolor}">{{tipsinfo}}</h4>
      </div>
    </div>
</template>

<script>
    import Service from '../../service/Service';
    export default {
        data(){
            return{
                tipsinfo:'',
                asin:'',
                imageurl:null,
                tipscolor:'#f44336',
                asinerror:false,
                imageurlerror:false,
                subTitle:null,
                productNameZh:null,

            }
        },
        created() {
            this.service = new Service();
        },
        mounted(){
        },
        methods:{
            submitimageurl(){
                this.imageurlerror=false;
                this.asinerror=false;
                this.tipsinfo='';
                this.tipscolor='#f44336';
                if(!this.asin||this.asin.trim()==''){
                    this.tipsinfo='ASIN不能为空!';
                    this.asinerror=true;
                }
                else if((!this.imageurl||this.imageurl.trim()=='')&&(!this.subTitle||this.subTitle.trim()=='')&&(!this.productNameZh||this.productNameZh.trim()=='')){
                    this.tipsinfo='图片网址、短标题、中文名称至少填写一个!';
                    this.imageurlerror=true;
                }
                else{
                    var obj={
                        asin:this.asin.trim(),
                        imageurl:this.imageurl?this.imageurl.trim():'',
                        subTitle:this.subTitle?this.subTitle.trim():'',
                        productNameZh:this.productNameZh?this.productNameZh.trim():''
                    };
                    const formData = new FormData();
                    formData.append('data', JSON.stringify(obj));
                    var url='system/setAsinData.php';
                    this.service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data => {
                        if(data.result=='success'){
                            this.tipscolor='#198754';
                            this.tipsinfo='提交成功！';
                        }
                    });
                }
            },
            cleardata(){
                this.imageurl=null;
                this.asin=null;
                this.tipsinfo='';
                this.subTitle=null;
                this.productNameZh=null;
            },
        }
    }
</script>